import React, { useState } from "react"

import calc1Png from "../../../assets/img/calc/1.png"
import calc2Png from "../../../assets/img/calc/2.png"
import calc3Png from "../../../assets/img/calc/3.png"
import calc4Png from "../../../assets/img/calc/4.png"
import calc5Png from "../../../assets/img/calc/5.png"
import calc6Png from "../../../assets/img/calc/6.png"
import calc7Png from "../../../assets/img/calc/7.png"
import calc8Png from "../../../assets/img/calc/8.png"
import calc9Png from "../../../assets/img/calc/9.png"
import calc10Png from "../../../assets/img/calc/10.png"
import calc11Png from "../../../assets/img/calc/11.png"
import calc12Png from "../../../assets/img/calc/12.png"
import calc13Png from "../../../assets/img/calc/13.png"
import calc14Png from "../../../assets/img/calc/14.png"
import calc15Png from "../../../assets/img/calc/15.png"
import calc16Png from "../../../assets/img/calc/16.png"
import calc17Png from "../../../assets/img/calc/17.png"
import calc18Png from "../../../assets/img/calc/18.png"
import calc19Png from "../../../assets/img/calc/19.png"
import calc20Png from "../../../assets/img/calc/20.png"
import calc21Png from "../../../assets/img/calc/21.png"
import calc22Png from "../../../assets/img/calc/22.png"
import calc23Png from "../../../assets/img/calc/23.png"
import calc24Png from "../../../assets/img/calc/24.png"
import calc25Png from "../../../assets/img/calc/25.png"
import calc26Png from "../../../assets/img/calc/26.png"
import calc27Png from "../../../assets/img/calc/27.png"
import calc28Png from "../../../assets/img/calc/28.png"
import BrandCard from "./BrandCard"
import { numberWithSpaces } from "../../helpers"

export default function CalcSection() {
  const brands = [
    [
      { name: "Porsche", price: "30000", image: calc1Png },
      { name: "Audi", price: "30000", image: calc2Png },
      { name: "Lexus", price: "65000", image: calc3Png },
      { name: "Toyota", price: "80000", image: calc4Png },
      { name: "Ford", price: "15000", image: calc5Png },
      { name: "Jaguar", price: "45000", image: calc6Png },
      { name: "Nissan", price: "40000", image: calc7Png },
    ],
    [
      { name: "Chevrolet", price: "35000", image: calc8Png },
      { name: "Honda", price: "60000", image: calc9Png },
      { name: "Volkswagen", price: "30000", image: calc10Png },
      { name: "BMW", price: "58000", image: calc11Png },
      { name: "Mazda", price: "46000", image: calc12Png },
      { name: "Renault", price: "23000", image: calc13Png },
      { name: "Land Rover", price: "34000", image: calc14Png },
    ],
    [
      { name: "Opel", price: "22000", image: calc15Png },
      { name: "Mitsubishi", price: "53000", image: calc16Png },
      { name: "Peugeot", price: "38000", image: calc17Png },
      { name: "Mercedes", price: "49000", image: calc18Png },
      { name: "Skoda", price: "30000", image: calc19Png },
      { name: "Kia", price: "83000", image: calc20Png },
      { name: "Subaru", price: "135000", image: calc21Png },
    ],
    [
      { name: "Hyundai", price: "83000", image: calc22Png },
      { name: "Infiniti", price: "97000", image: calc23Png },
      { name: "ВАЗ", price: "12000", image: calc24Png },
      { name: "Citroen", price: "38000", image: calc25Png },
      { name: "Fiat", price: "19000", image: calc26Png },
      { name: "Volvo", price: "32000", image: calc27Png },
      { name: "Suzuki", price: "34000", image: calc28Png },
    ],
  ]

  const [selected, setSelected] = useState("Audi")
  const [price, setPrice] = useState("30000")

  return (
    <section className="calc">
      <div className="container">
        <div
          className="calc-content wow slideInRight"
          style={{ /*visibility: "hidden",*/ animationName: "none" }}
        >
          <div className="calc-header text-center">
            Мы осуществляем скупку катализаторов от отечественных авто и
            иномарок.
            <br />
            До 135 000 руб. за 1 кг. До 75% от LME.
          </div>
          <div className="calc-body">
            <div className="auto-blocks">
              {brands.map((group, index) => {
                return (
                  <div key={`brand-group-${index}`} className="auto-blocks-row">
                    {group.map((brand, index) => {
                      return (
                        <BrandCard
                          key={`brand-price-${index}`}
                          name={brand.name}
                          price={brand.price}
                          image={brand.image}
                          selected={selected === brand.name}
                          setSelected={setSelected}
                          setPrice={setPrice}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="p-3">
            *Указана средняя стоимость. В зависимости от конкретной модели
            автомобиля цена может варьироваться.
          </div>
          <div className="calc-footer">
            <div className="calc-footer__row">
              <div className="calc-footer__info">
                За 1кг катализатора от <span id="calc-name">Audi</span> в
                среднем мы платим
              </div>
              <div className="calc-footer__cost">
                <span id="calc-cost">{numberWithSpaces(price)}</span> ₽*
              </div>
              <div className="calc-footer__dopinfo">
                Дизель — на 25% дешевле
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
