import React from "react"

export default function BrandCard({
  name,
  price,
  image,
  selected,
  setSelected,
  setPrice,
}) {
  return (
    <div
      onClick={() => {
        setSelected(name)
        setPrice(price)
      }}
      className="auto-block-wrap"
    >
      <div
        className={`auto-block ${selected ? "auto-block--active" : ""}`}
        data-price={price}
      >
        <div className="auto-block__img">
          <img src={image} alt={name} />
        </div>
        <div className="auto-block__name">{name}</div>
      </div>
    </div>
  )
}
