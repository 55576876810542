import React, { useRef, useState } from "react"
import { useSetRecoilState } from "recoil"
import { callbackModalOpen } from "./atoms"

import delivery1Png from "../../assets/img/delivery/1.png"
import delivery2Png from "../../assets/img/delivery/2.png"
import delivery3Png from "../../assets/img/delivery/3.png"
import emailjs from "emailjs-com"

export default function DeliverySection() {
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)

  const form = useRef()

  const [city, setCity] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [radio, setRadio] = useState(0)

  const [agreed, setAgreed] = useState(true)

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_44gyk98",
        "template_t53r1gx",
        form.current,
        "user_p3PeVrvCFdq6zxN3MjeOj"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <section className="delivery" id="dostavka">
      <div className="container">
        <div className="delivery-left">
          <h3 className="delivery-title">
            Варианты <span>доставки</span>
          </h3>
          <div className="delivery-info">
            <div className="delivery-info-blocks">
              <div
                className="delivery-info-block wow fadeInLeft"
                //data-wow-delay="0s"
                style={
                  {
                    // visibility: "hidden",
                    // animationDelay: "0s",
                    // animationName: "none",
                  }
                }
              >
                <div className="delivery-info-block__img">
                  <img src={delivery1Png} alt="delivery" />
                </div>
                <div className="delivery-info-block__text">
                  <h4 className="delivery-info-block__title">
                    Бесплатный <span>выезд нашего специалиста</span> в вашем
                    городе
                  </h4>
                  <p className="delivery-info-block__descr">
                    В этом случае выплата происходит сразу после XRF анализа,
                    который наш специалист выполнит на месте вызова.
                  </p>
                </div>
              </div>
              <div
                className="delivery-info-block wow fadeInLeft"
                // data-wow-delay="0.3s"
                style={
                  {
                    // visibility: "hidden",
                    // animationDelay: "0.3s",
                    // animationName: "none",
                  }
                }
              >
                <div className="delivery-info-block__img">
                  <img src={delivery2Png} alt="delivery" />
                </div>
                <div className="delivery-info-block__text">
                  <h4 className="delivery-info-block__title">
                    Бесплатная <span>отправка почтой</span> по России и СНГ
                  </h4>
                  <p className="delivery-info-block__descr">
                    Мы сами оплачиваем доставку в пределах РФ и СНГ. В этом
                    случае выплата происходит после анализа, в день получения
                    посылки.
                  </p>
                </div>
              </div>
              <div
                className="delivery-info-block wow fadeInLeft"
                data-wow-delay="0.6s"
                style={
                  {
                    // visibility: "hidden",
                    // animationDelay: "0.6s",
                    // animationName: "none",
                  }
                }
              >
                <div className="delivery-info-block__img">
                  <img src={delivery3Png} alt="delivery" />
                </div>
                <div className="delivery-info-block__text">
                  <h4 className="delivery-info-block__title">
                    <span>Личная доставка</span> в наш цех в г.Калуга
                  </h4>
                  <p className="delivery-info-block__descr">
                    Вы можете приехать в цех и присутствовать при анализе.
                    Расчет стоимости и выплата происходит в тот же день.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="delivery-info__button-wrap">
            <button
              onClick={() => setCallbackModalOpen(true)}
              className="button delivery-info__button button--solid-orange button--shadow"
            >
              Вызвать специалиста
            </button>
          </div>
        </div>
        <div className="delivery-right">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="ajax_form af_example delivery-form"
          >
            <div className="delivery-form__header">
              Как с нами
              <br /> связаться
            </div>
            <div className="delivery-form__inputs inputs-group">
              <input
                value={city}
                onChange={e => setCity(e.target.value)}
                type="text"
                className="delivery-form__input input"
                name="city"
                placeholder="Город"
              />
              <input
                value={message}
                onChange={e => setMessage(e.target.value)}
                type="text"
                className="delivery-form__input input"
                name="message"
                placeholder="Имя"
              />
              <input
                value={phone}
                onChange={e => {
                  let digits = e.target.value.replace(/\D/g, "")
                  setPhone(digits)
                }}
                type="text"
                className="delivery-form__input input"
                name="phone"
                placeholder="Телефон"
                required
              />
            </div>
            <div className="delivery-form__radio-block">
              <div className="delivery-form__radio-block__name">Вы:</div>
              <div className="delivery-form__radios">
                <label className="delivery-form__radio">
                  <input
                    name="client"
                    value="Автовладелец"
                    type="radio"
                    onClick={() => setRadio(0)}
                    checked={radio === 0}
                  />
                  <span className="delivery-form__radio__check">
                    Автовладелец
                  </span>
                </label>
                <label className="delivery-form__radio">
                  <input
                    name="client"
                    value="Сервис"
                    type="radio"
                    onClick={() => setRadio(1)}
                    checked={radio === 1}
                  />
                  <span className="delivery-form__radio__check">Сервис</span>
                </label>
                <label className="delivery-form__radio">
                  <input
                    name="client"
                    value="Разборка"
                    type="radio"
                    onClick={() => setRadio(2)}
                    checked={radio === 2}
                  />
                  <span className="delivery-form__radio__check">Разборка</span>
                </label>
                <label className="delivery-form__radio">
                  <input
                    name="client"
                    value="Металлобаза"
                    type="radio"
                    onClick={() => setRadio(3)}
                    checked={radio === 3}
                  />
                  <span className="delivery-form__radio__check">
                    Металлобаза
                  </span>
                </label>
                <label className="delivery-form__radio">
                  <input
                    name="client"
                    value="Иное"
                    type="radio"
                    onClick={() => setRadio(4)}
                    checked={radio === 4}
                  />
                  <span className="delivery-form__radio__check">Иное</span>
                </label>
              </div>
            </div>
            <div
              className="mb-4"
              style={{ fontSize: "16px", lineHeight: "1.5rem" }}
            >
              От Вашего статуса партнерства будут зависеть индивидуальные
              условия работы
            </div>
            <label className="delivery-form__personal">
              <input
                type="checkbox"
                required
                checked={agreed}
                onClick={() => setAgreed(!agreed)}
              />
              <span className="delivery-form__personal-check" />
              <span className="delivery-form__personal-text">
                Я согласен с условиями обработки моих персональных данных
              </span>
            </label>
            <div className="delivery-form__button-wrap">
              <button
                type="submit"
                className="delivery-form__button button button--o-gray button--shadow"
              >
                Отправить заявку
              </button>
            </div>

            <input
              type="hidden"
              name="af_action"
              value="bc72710a66835e75f68ab2c3fd4b6bac"
            />
            <input
              type="text"
              name="org"
              value=""
              className="_org"
              style={{
                visibility: "hidden",
                height: "0",
                width: "0",
                padding: "0",
                border: "none",
              }}
            />
          </form>
        </div>
      </div>
    </section>
  )
}
