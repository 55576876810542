import React from "react"
import { Link } from "gatsby"

import price1Webp from "../../assets/img/price/1.webp"
import price1Png from "../../assets/img/price/1.png"
import price2Webp from "../../assets/img/price/2.webp"
import price2Png from "../../assets/img/price/2.png"
import price3Webp from "../../assets/img/price/3.webp"
import price3Png from "../../assets/img/price/3.png"

import PriceActions from "./PriceActions"

export default function PriceSection() {
  return (
    <section className="price" id="price">
      <div className="container">
        <h2 className="section-title section-title--extra-bold price-title text-center">
          Цены на б/у катализаторы
        </h2>
        <h3 className="price-subtitle text-center">
          Принимаем любые автомобильные катализаторы по самым высоким ценам в
          РФ.
        </h3>
        <div className="price-cards">
          <a
            href="https://katalikauto.ru/keramicheskie-katalizatoryi"
            aria-label="Каталог керамических катализаторов"
          />
          <div
            className="price-card-col wow fadeInUp"
            // data-wow-delay="0.3s"
            style={
              {
                // visibility: "visible",
                // animationDelay: "0.3s",
                // animationName: "fadeInUp",
              }
            }
          >
            <Link
              to="/keramicheskie-katalizatoryi"
              aria-label="Каталог керамических катализаторов"
            >
              <div className="price-card">
                <h5 className="price-card__header">
                  <span className="price-card__header-material">
                    Керамические
                  </span>
                  <span className="price-card__header-type">катализаторы</span>
                </h5>
                <div className="price-card__img mx-auto">
                  <picture>
                    <source srcSet={price1Webp} type="image/webp" />
                    <source srcSet={price1Png} type="image/jpeg" />
                    <img src={price1Png} alt="Керамический катализатор" />
                  </picture>
                </div>
                <div className="price-card__rows">
                  <div className="price-card__row">
                    Импортные до <span>110 000</span> руб./кг.
                  </div>
                  <div className="price-card__row">
                    Отечественные до <span>18 000</span> руб./кг.
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="price-card-col wow fadeInUp"
            // data-wow-delay="0.6s"
            style={
              {
                // visibility: "visible",
                // animationDelay: "0.6s",
                // animationName: "fadeInUp",
              }
            }
          >
            <Link
              to="/metallicheskie"
              aria-label="Каталог металлических катализаторов"
            >
              <div className="price-card">
                <h5 className="price-card__header">
                  <span className="price-card__header-material">
                    Металлические
                  </span>
                  <span className="price-card__header-type">катализаторы</span>
                </h5>
                <div className="price-card__img mx-auto">
                  <picture>
                    <source srcSet={price2Webp} type="image/webp" />
                    <source srcSet={price2Png} type="image/jpeg" />
                    <img src={price2Png} alt="Металлический катализатор" />
                  </picture>
                </div>
                <div className="price-card__rows">
                  <div className="price-card__row">
                    Импортные до <span>9 700</span> руб./кг.
                  </div>
                  <div className="price-card__row">
                    Отечественные до <span>3 700</span> руб./кг.
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="price-card-col wow fadeInUp"
            // data-wow-delay="0.9s"
            style={
              {
                // visibility: "visible",
                // animationDelay: "0.9s",
                // animationName: "fadeInUp",
              }
            }
          >
            <Link to="/sazhevyie-filtry" aria-label="Каталог сажевых фильтров">
              <div className="price-card">
                <h5 className="price-card__header">
                  <span className="price-card__header-material">Сажевые</span>
                  <span className="price-card__header-type">фильтры</span>
                </h5>
                <div className="price-card__img mx-auto">
                  <picture>
                    <source srcSet={price3Webp} type="image/webp" />
                    <source srcSet={price3Png} type="image/jpeg" />
                    <img src={price3Png} alt="Сажевый фильтр" />
                  </picture>
                </div>
                <div className="price-card__rows">
                  <div className="price-card__row">
                    Импортные и Отечественные
                  </div>
                  <div className="price-card__row">
                    до <span>12 000</span> руб./кг.
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <PriceActions />
      </div>
    </section>
  )
}
