import React from "react"

import HeadSection from "../HeadSection"
import Screen from "./Screen"
import PriceSection from "./PriceSection"
import CalcSection from "./CalcSection"
import AdvantagesSection from "./AdvantagesSection"
import DeliverySection from "./DeliverySection"
import LocationSection from "./LocationSection"
import OfferSection from "./OfferSection"
import Layout from "../Layout"

import { citySwitchDescription, citySwitchTitle } from "../requisites"

export default function Home({ city = "Калуга" }) {
  return (
    <>
      <HeadSection
        title={citySwitchTitle(city)}
        description={citySwitchDescription(city)}
      />
      <Layout city={city}>
        <Screen city={city} />
        <PriceSection />
        <CalcSection />
        <AdvantagesSection />
        <DeliverySection />
        <LocationSection city={city} />
        <OfferSection citySwitch={city} />
      </Layout>
    </>
  )
}
