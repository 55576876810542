import React from "react"
import { float2int } from "../../helpers"
import { useQuery } from "react-query"

export default function RatesDesktop() {
  const { isLoading, error, data, isFetching } = useQuery("ratesData", () =>
    fetch("https://kitco-parcer.herokuapp.com/api/v1/kitco").then(res =>
      res.json()
    )
  )

  if (isLoading) return "Загрузка..."

  if (error) return "Произошла ошибка: " + error.message

  return (
    <div
      // data-wow-duration="1.1s"
      className="screen-body-img wow fadeInRight p-3"
      style={
        {
          // visibility: "visible",
          // animationDuration: "1.1s",
          // animationName: "fadeInRight",
        }
      }
    >
      <div className="row">
        <div className="col-12 row mx-1 px-5 justify-content-around text_calc">
          <div className="col-8 m-0 p-0">
            {isFetching ? "Обновление..." : `Котировки на ${data.date}`}
          </div>
          <div className="col-4 m-0 p-0  text-right">
            {" "}
            ${" "}
            <span className=" price_rub">{Number(data.USDRUB).toFixed(2)}</span>
          </div>
        </div>
      </div>
      <div className="col-12 row justify-content-around pt-4 ml-0">
        <div className="col-4">
          <div className="big_calc">Pt</div>
          <div className="small_calc">Platinum</div>
        </div>

        <div className="col-4">
          <div className="big_calc">Pd</div>
          <div className="small_calc">Palladium</div>
        </div>
        <div className="col-4">
          <div className="big_calc">Rh</div>
          <div className="small_calc">Rhodium</div>
        </div>
      </div>
      <div className="col-12 row justify-content-around pt-2  px-0  mt-3 ml-0 border_calc">
        <div className="col-4 p-0 text-center">
          <div className="price_calc">
            {float2int((data.ptValue / 31.1) * 0.7 * data.USDRUB)}{" "}
            <span className="small2_calc">₽</span>
          </div>
        </div>
        <div className="col-4 p-0 text-center">
          <div className="price_calc">
            {float2int((data.plValue / 31.1) * 0.7 * data.USDRUB)}{" "}
            <span className="small2_calc">₽</span>
          </div>
        </div>
        <div className="col-4 p-0 text-center">
          <div className="price_calc">
            {float2int((data.rhValue / 31.1) * 0.7 * data.USDRUB)}{" "}
            <span className="small2_calc">₽</span>
          </div>
        </div>
      </div>
    </div>
  )
}
