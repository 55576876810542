import React from "react"
import LazyLoad from "react-lazyload"
import videoSrc from "../../../assets/img/video3.mp4"

export default function VideoBackground() {
  return (
    <div
      className="banner-roller hidden md:block"
      id="viewport"
      style={{ width: "1920px", height: "944px" }}
    >
      <LazyLoad>
        <video
          id="video"
          autoPlay
          loop
          muted
          playsInline // for iOS
          width="848"
          height="480"
          style={{ width: "1920px", height: "1086.79px" }}
        >
          <source
            src={videoSrc}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
      </LazyLoad>
    </div>
  )
}
