import React from "react"

import { useSetRecoilState } from "recoil"
import { callbackModalOpen } from "../atoms"

import Header from "../Header"
import Features from "./Features"

import VideoBackground from "./VideoBackground"
import RatesDesktop from "./RatesDesktop"
import RatesMobile from "./RatesMobile"

import { citySwitchSubtitle } from "../../requisites"

export default function Screen({ city }) {
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)

  return (
    <div className="screen">
      <VideoBackground />
      <Header city={city} />
      <div className="screen-body">
        <div className="container">
          <div className="screen-body-info ">
            <h1 className="screen-body-info__title big-title">
              Прием <span>катализаторов</span> и <span>сажевых фильтров</span>
            </h1>
            <h2 className="screen-body-info__subtitle">
              {citySwitchSubtitle(city)}
            </h2>

            <div className="screen-body-info__actions">
              <button
                onClick={() => setCallbackModalOpen(true)}
                className="button button--solid-orange"
              >
                Вызвать специалиста
              </button>
              <button
                onClick={() => setCallbackModalOpen(true)}
                className="button button--o-white"
              >
                Узнать стоимость
              </button>
            </div>
          </div>
          <RatesDesktop />
        </div>
        <RatesMobile />
      </div>
      <Features />
    </div>
  )
}
